.App {
  text-align: left;
  background-color:#ffffff;
  height: 100%
}

p {
  padding: 5px
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-left: 20px;
}

.welcome-message {
  margin-left: 20px;
}

.header-line {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

}

.button-reset-api {
  margin-left: 40px;
  height: 1.7em;
}

.game-score {
  margin-left: auto;
  margin-right: 2em;
  order: 2;
}
